import React, { Component } from 'react';
import NoImg from 'assets/img/noimg.svg';
import moment from 'moment';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button';
import { intersect } from 'lib';

class Days extends Component { 
	constructor(props) {
    	super(props);
    	const {mealplan, timestamp, products, usersides, altmeals,typeList} = props;
        var activemeals = [];
        var mealsGroup = [];
        var mealsSides = [];
        var firstType = false;
        var newAltmeal = [];
        var activemealsUpdated = [];
    	mealplan[timestamp].map((value, index) => {
    		if (!firstType) {
    			firstType = value.mealType_id;
    		}
    		activemeals[value.mealType_id] = value.dish_id;
        }); 
        
        // REDUCE METHOD
        mealsGroup[timestamp] = mealplan[timestamp].reduce((r,a)=>{
            r[a.delivery_name] = [...r[a.delivery_name] || [], a];
            return r;
        }, {});
        // MODIFY ACTIVEMEALS
        
        var activeMealPlan = {};
        activeMealPlan[timestamp] = {};
        Object.keys(mealsGroup[timestamp]).map((value,index) => {
            var mealtypeObj = [];
            mealsGroup[timestamp][value].map((v, ind) => {
                const { mealType_id: mealtypeid, dish_id: dishid, side_id } = v;
                if(typeList[mealtypeid]['parent_id']!=null && typeList[mealtypeid]['parent_id']!=0){
                    if(mealsSides[mealtypeid]==undefined){
                        mealsSides[mealtypeid] = {};
                    }
                    mealsSides[mealtypeid][side_id] =dishid;
                }
                //mealtypeObj.push({mealtypeid,dishid});
                mealtypeObj[mealtypeid] = dishid;
            });
            activeMealPlan[timestamp][value] = mealtypeObj;
        });
		this.initialState = {
    		edit: false, 
    		edit_type: firstType,
    		activemeals: activemeals,
			notselect: [],
			dislikeproducts:[],
      		allergyproducts:[],
    		notes: [],
            mealsGroup: mealsGroup,
            editBag:'',
            activemealplan: activeMealPlan,
            mealsSides
	    };

    	this.state = this.initialState;
	}
    
	editDay = (timestamp,keys) => {
        const { setUnsavedVal }=this.props;
  		this.setState({
              edit: true,
              editBag: keys,
          });
          setUnsavedVal('push',timestamp+keys)
  	}

    changeMealside = (mealType_id,sideId,dishId)=>{
        const { mealsSides } = this.state;
        this.setState({ mealsSides: { ...mealsSides, [mealType_id]: { ...mealsSides[mealType_id], [sideId]:dishId } } });
    }  
    
  	changeEditType = (type) => {
  		this.setState({
  			edit_type: type,
  		});
  	}
 
    checkSameDayDish(type, product_id){
        var sameDishExist = false;
        const {mealplan, timestamp, products, usersides, altmeals} = this.props;
        var {activemeals, notselect,editBag, activemealplan, mealsGroup} = this.state;
        Object.keys(mealsGroup[timestamp]).forEach(function(values) {
            mealsGroup[timestamp][values].forEach(function(val, index){
                if(val.mealType_id == type && val.dish_id == product_id){
                    sameDishExist = true;
                    return '';
                }
            });
        });
        return sameDishExist;
    }
    checkSamyDayTypeDish(type, product_id,changeBag){
        let sameDayTypeDishExist = false;
        const { timestamp,typeList} = this.props;
        var {activemealplan, mealsGroup} = this.state;
        var dishAlreadySelected = false;
        var selectedType = '';
        mealsGroup[timestamp][changeBag].map((val)=>{
            if(val.dish_id == product_id){
                dishAlreadySelected = true;
                selectedType = val.mealType_id;
                return '';
            }
        });
        if(dishAlreadySelected){
          var alertText = "Please note you have selected same dish for "+ typeList[selectedType].title +". Do you want to select same Dish? "   
          if(window.confirm(alertText)){
            activemealplan[timestamp][changeBag][type] = product_id;
            mealsGroup[timestamp][changeBag].map((val,indx) => {
                if(mealsGroup[timestamp][changeBag][indx].mealType_id == type){
                    mealsGroup[timestamp][changeBag][indx].dish_id = product_id; 
                }
            });
            this.setState({
                activemealplan: activemealplan, 
            });

         }else{
             return true;
         }
        }
    }
   disLikeProductsFunc = (type, product_id, changeBag) => {
    const {mealplan, timestamp, products, usersides, altmeals} = this.props;
    var {activemeals, notselect,editBag, activemealplan, mealsGroup, allergyproducts, dislikeproducts} = this.state;
        if (confirm("This dish has an ingredient which is in Customer\'s dislike list. Please note we would not be able to remove the ingredient, if you choose to opt for this dish.If not, we would recommend you to select another dish")) {
            return 'confirm';
        }else{
            return 'notconfirm'
        }
   }
   finalOtherDays = (type, product_id, changeBag) => {
    const {mealplan, timestamp, products, usersides, altmeals} = this.props;
    var {activemeals, notselect,editBag, activemealplan, mealsGroup, allergyproducts, dislikeproducts} = this.state;
    debugger;
    if (notselect.indexOf(product_id) == -1) {
        if(this.checkSameDayDish(type, product_id)){
            if(window.confirm('Please note, you have selected the same dish on the other bag!')){
                activemealplan[timestamp][changeBag][type] = product_id;
                mealsGroup[timestamp][changeBag].map((val,indx) => {
                    if(mealsGroup[timestamp][changeBag][indx].mealType_id == type){
                        mealsGroup[timestamp][changeBag][indx].dish_id = product_id; 
                    }
                });
                this.setState({
                    activemealplan: activemealplan, 
                });

            }
        }else if(this.checkSamyDayTypeDish(type, product_id,changeBag)){

        }else{
            activemealplan[timestamp][changeBag][type] = product_id;
                mealsGroup[timestamp][changeBag].map((val,indx) => {
                    if(mealsGroup[timestamp][changeBag][indx].mealType_id == type){
                        mealsGroup[timestamp][changeBag][indx].dish_id = product_id; 
                    }
                });
                this.setState({
                    activemealplan: activemealplan, 
                });
        }
    }else{
        this.notConirmProductSelect(type, product_id, changeBag);
    }
   }
   notConirmProductSelect = (type, product_id, changeBag) => {
    
   }
  	changeActive = (type, product_id, changeBag) => {
        const {mealplan, timestamp, products, usersides, altmeals} = this.props;
        var {activemeals, notselect,editBag, activemealplan, mealsGroup, allergyproducts, dislikeproducts} = this.state;
        var allowSelectDish = true;
        var oldproductId = activemealplan[timestamp][changeBag][type];
        // CHECK FOR DISLIKES AND ALERGIES START
          if(allergyproducts.indexOf(product_id) != -1){
		    alert('The dish can\'t be selected because of Customer\'s allergy');
            return '';
	      }
          if(dislikeproducts.indexOf(product_id) != -1){ // CHECK DISLIKE
            var dislikeResponse = this.disLikeProductsFunc(type, product_id, changeBag);
            if(dislikeResponse == 'confirm'){
                this.finalOtherDays(type, product_id, changeBag);
            }else if(dislikeResponse == 'notconfirm'){
                this.notConirmProductSelect(type, product_id, changeBag);
            }
          }else{
            this.finalOtherDays(type, product_id, changeBag);
          }
  	}

 	updateNotselectableProducts = (product_id,type) => {
		// var {notselect} = this.state;
		// if (notselect.indexOf(product_id) == -1) {
		// 	notselect.push(product_id);
		// 	this.setState({ 
		// 		notselect: notselect, 
		// 	});
	  //   }
		var {dislikeproducts,allergyproducts} = this.state;
		if(type=='allergy'){
		  if (allergyproducts.indexOf(product_id) == -1) {
			allergyproducts.push(product_id);
			this.setState({
			  allergyproducts: allergyproducts, 
			});
		  }
		}else if(type=='dislike'){
		  if (dislikeproducts.indexOf(product_id) == -1) {
			   dislikeproducts.push(product_id);
			  this.setState({
				dislikeproducts: dislikeproducts, 
			  });
		  }
	
		}  
	}

  	updateNote = (text, product_id,type_id) => {
  		if (text.length <= 32) {
	      const {all_notes, timestamp} = this.props;
	      const {notes} = this.state;
	    //   notes[product_id] = text;
	      this.setState({
            notes: {...notes,[product_id]:{...notes[product_id],[type_id]:text}}
        });
  		}
  	}

  	saveNoteBtn = (product_id,type_id) => {
  		const {timestamp, order_id, user_id} = this.props;
  		const {notes} = this.state;
  		if (typeof(notes[product_id] != 'undefined') && notes[product_id] != '' && notes[product_id] != null && notes[product_id][type_id] != null) {
            this.props.socket.emit('default_dishes', { type: 'setNote', data: { order_id: order_id, user_id: user_id, dish_id: product_id, note_text: notes[product_id][type_id], date: timestamp,mealtype_id:type_id } });
        }
  	}

  	finishClick = (timestamp, order_id, socket, finishBag) => {
        const {setUnsavedVal} = this.props;
        const { mealsSides } = this.state;
  		this.setState({
  			edit: false,
          });
        setUnsavedVal('pop',timestamp+finishBag);
      	socket.emit('order', { type: 'saveEditPlan', data: { orderId: order_id, timestamp: timestamp, activemeals: this.state.activemealplan[timestamp][finishBag], bagname: finishBag,mealsSides } });
  	}

      renderEditMenu(keys){
        const {timestamp, mealplan, products, typeList, ingredientList, altmeals, socket, order_id, userData, all_notes, pause_start, pause_end, dishLabels, editSides, usersides, sidesList} = this.props;
		const {edit, activemeals, notes, mealsGroup,editBag} = this.state;
        return (<ul className="mealrow-dish-groups">
        {mealsGroup[timestamp][keys].map((values) =>{
             if(typeList[values.mealType_id].parent_id==null || typeList[values.mealType_id].parent_id==0){
                const type = typeList[values.mealType_id].title;
                const {edit_type} = this.state;
                var active = false;
                if (edit_type == values.mealType_id) {
                    active = true;
                } 
                return(<li className={!active || 'mealrow-active-type'} onClick={() => this.changeEditType(values.mealType_id)}>{type}</li>);
            }
        })}
        {<li className="mealrow-finish-btn" onClick={() => this.finishClick(timestamp, order_id, socket, keys)}>Finish</li>}
        </ul>);
      }
      renderDefaultMenu(bagName,indx=0){
        const {timestamp, mealplan, products, typeList, ingredientList, altmeals, socket, order_id, userData, all_notes, pause_start, pause_end, dishLabels, editSides, usersides, sidesList,ParentTypeId} = this.props;
        const {edit, activemeals, notes, activemealsExtraBag, MealplanWithoutAdditional, MealplanWithAdditional, activemealplan, mealsGroup,mealsSides} = this.state;
        const date = moment.unix(timestamp);
        const {allergies, dislikes, dishlikes, dishdislikes} = userData;

        var total_calories = 0; 
		var total_fat = 0;
		var total_protein = 0;
		var total_carbs = 0;
        var bagCount = 0;
       
        mealsGroup[timestamp][bagName].map((values) =>{
            var product_id = values.dish_id;
            var type_id = values.mealType_id;
            const { title:type, parent_id } =typeList[type_id];
            if(parent_id==null || parent_id==0){
            var name = products[product_id].title;
            // console.log(name);
            var nickname = products[product_id].nickname;
            var calories = products[product_id].calories/1000;
            var carbs = products[product_id].carb/1000;
            var protein = products[product_id].protein/1000;
            var fat = products[product_id].fat/1000;
             /* Add the sides based parent id */
             if(ParentTypeId[type_id]!=undefined){
                Object.keys(mealsSides).length>0&&mealsSides[ParentTypeId[type_id]]!=undefined&&Object.keys(mealsSides[ParentTypeId[type_id]]).map((side_id)=>{
                    var dishid=mealsSides[ParentTypeId[type_id]][side_id];
                    // sideHtml+= products[dishid].title+'<br/>';
                    if(products[dishid].title!='No side'){
                        calories = calories + (products[dishid].calories/1000);
                        carbs = carbs + (products[dishid].carb/1000);
                        protein = protein + (products[dishid].protein/1000);
                        fat = fat + (products[dishid].fat/1000);
                    }
                    
                    return (<p className='side-label'>{products[dishid].title}</p>);
                })
            }
            if(name == 'No side'){
                calories=0;
                carbs=0;
                protein=0;
                fat=0;
            }
        var showdef = true;
        if (usersides && typeof(usersides[timestamp]) != 'undefined') {
            // User's optional sides:
            if (typeof(usersides[timestamp][product_id]) != 'undefined') {
                var thesides = usersides[timestamp][product_id];
                var prefix = ' with ';
                Object.keys(thesides).map( (el, idx) => {
                    var side_id = thesides[el].side_id;
                    if (typeof(products[side_id]) != 'undefined' && thesides[el].type == 'alternative') {
                        showdef = false;
                        if(products[side_id].title!= 'No side'){
                        calories = calories + products[side_id].calories/1000;
                        carbs = carbs + products[side_id].carb/1000;
                        protein = protein + products[side_id].protein/1000;
                        fat = fat + products[side_id].fat/1000;
                        }
                        name = name + prefix + products[side_id].title;
                        nickname = nickname + prefix + products[side_id].title;
                        prefix = ' & ';
                    }
                });
            }
        } 
        
        if (showdef) {
            // Default sides:
            var thesides = products[product_id].all_sides;
            var mealTypeSides = products[product_id].mealTypeSides;
            var prefix = ' with ';
            if(mealTypeSides[type_id]!='undefined'&&typeof(products[mealTypeSides[type_id]]) != 'undefined'){
                if(products[mealTypeSides[type_id]].title != 'No side'){
                    calories = calories + products[mealTypeSides[type_id]].calories/1000;
                    carbs = carbs + products[mealTypeSides[type_id]].carb/1000;
                    protein = protein + products[mealTypeSides[type_id]].protein/1000;
                    fat = fat + products[mealTypeSides[type_id]].fat/1000;
                }
                    name = name + prefix + products[mealTypeSides[type_id]].title;
                    nickname = nickname + prefix + products[mealTypeSides[type_id]].title;
                    prefix = ' & ';
               
              
            }
            // Object.keys(thesides).map( (el, idx) => {
            //     if (typeof(products[thesides[el]]) != 'undefined') {
            //         calories = calories + products[thesides[el]].calories/1000;
            //         carbs = carbs + products[thesides[el]].carb/1000;
            //         protein = protein + products[thesides[el]].protein/1000;
            //         fat = fat + products[thesides[el]].fat/1000;
            //         name = name + prefix + products[thesides[el]].title;
            //         nickname = nickname + prefix + products[thesides[el]].title;
            //         prefix = ' & ';
            //     }
            // });
        
        }
        
        if (usersides && typeof(usersides[timestamp]) != 'undefined') {
        // User's optional sides:
            if (typeof(usersides[timestamp][product_id]) != 'undefined') {
                var thesides = usersides[timestamp][product_id];
                Object.keys(thesides).map( (el, idx) => {
                    var side_id = thesides[el].side_id;
                    if (typeof(products[side_id]) != 'undefined' && thesides[el].type == 'addons') {
                        showdef = false;
                        if(products[side_id].title!= 'No side'){
                            calories = calories + products[side_id].calories/1000;
                            carbs = carbs + products[side_id].carb/1000;
                            protein = protein + products[side_id].protein/1000;
                            fat = fat + products[side_id].fat/1000;
                        }
                        name = name + prefix + products[side_id].title;
                        nickname = nickname + prefix + products[side_id].title;
                        prefix = ' & ';
                    }
                });
            }
        } 
       
        total_calories += calories; 
        total_fat += fat;
        total_protein += protein;
        total_carbs += carbs;
    }
    });
        if(bagName == 'InitialBag'){
            bagCount = 1;
        }else{
            var bagSplit = bagName.split("#");
            bagCount = bagSplit[1];
        }
        return (
        <div>{Object.keys(mealsGroup[timestamp]).length > 1 ? <h5>Customer Bag #{bagCount}</h5>:''}
        <ul className="mealrow-dish-groups">
        <li onClick={ () => this.editDay(timestamp,bagName) }>Change items</li>
        <li className="can-change">You can change the selection on your own</li>
        <li className="can-change mealrow-calories-summary"><b>Calories:</b> {total_calories}, <b>Fat:</b> {total_fat}, <b>Protein:</b> {total_protein}, <b>CHO:</b> {total_carbs} </li>
        </ul></div>);

      }
      renderItem(type_id,product_id,bagname,delivery_name){
        const {timestamp, mealplan, products, typeList, ingredientList, altmeals, socket, order_id, userData, all_notes, pause_start, pause_end, dishLabels, editSides, usersides, sidesList, ParentTypeId} = this.props;
        const {edit, activemeals, notes, activemealsExtraBag, MealplanWithoutAdditional, MealplanWithAdditional, activemealplan, mealsSides} = this.state;
        const date = moment.unix(timestamp);
        const {allergies, dislikes, dishlikes, dishdislikes} = userData;
        var total_calories = 0; 
        var total_fat = 0;
        var total_protein = 0;
        var total_carbs = 0;
        var bagCount = 0;
        
        if (pause_start && timestamp >= pause_start && timestamp <= pause_end) {
            return null;
        }
        const { title:type, parent_id } =typeList[type_id];
        var name = products[product_id].title;
        var nickname = products[product_id].nickname;
        var calories = products[product_id].calories/1000;
        var carbs = products[product_id].carb/1000;
        var protein = products[product_id].protein/1000;
        var fat = products[product_id].fat/1000;
        var image = (<img className="mealrow-img" src={NoImg}></img>);
        if (products[product_id].image != null) {
            image = (<img className="mealrow-img" src={products[product_id].image}></img>);
        } 
        
        var note = null;
        if (notes[product_id] != undefined && notes[product_id][type_id] != undefined && notes[product_id][type_id] != null ) {
            note = (<b className="mealrow-note-value">{notes[product_id][type_id]}</b>);
        } else if (all_notes) {
            if((all_notes[timestamp] != undefined ) && (all_notes[timestamp][product_id] != undefined) ){
                if(all_notes[timestamp][product_id][type_id]!=undefined && typeof(all_notes[timestamp][product_id])=='object'){
                    note = (<b className="mealrow-note-value">{all_notes[timestamp][product_id][type_id]}</b>);
                }else if(all_notes[timestamp][product_id]!=undefined && typeof(all_notes[timestamp][product_id])=='string'){
                    note = (<b className="mealrow-note-value">{all_notes[timestamp][product_id]}</b>);
                }
            }
        }
        
        var label = false;
        if ( dishLabels && products[product_id] && typeof(dishLabels[products[product_id].label_id]) != 'undefined') {
            label = dishLabels[products[product_id].label_id];
        }
        
        var fixed_side=true;
        var showdef = true;
        if (usersides && typeof(usersides[timestamp]) != 'undefined') {
            // User's optional sides:
            if (typeof(usersides[timestamp][product_id]) != 'undefined'&&typeof(usersides[timestamp][product_id][delivery_name][type_id]) != 'undefined') {
                var thesides = usersides[timestamp][product_id][delivery_name][type_id];
                var prefix = ' with ';
                Object.keys(thesides).map( (el, idx) => {
                    var side_id = thesides[el].side_id;
                    if(thesides[el].type=='addons'&&side_id<0){
                        fixed_side=false;
                    }
                    if (typeof(products[side_id]) != 'undefined') {
                        showdef = false;
                        calories = calories + products[side_id].calories/1000;
                        carbs = carbs + products[side_id].carb/1000;
                        protein = protein + products[side_id].protein/1000;
                        fat = fat + products[side_id].fat/1000;
                        name = name + prefix + products[side_id].title;
                        nickname = nickname + prefix + products[side_id].title;
                        prefix = ' & ';
                    }
                });
            }
        } 
        
        if (showdef) {
            // Default sides:
            var thesides = products[product_id].all_sides;
            var mealTypeSides = products[product_id].mealTypeSides;
            var prefix = ' with ';
            if(mealTypeSides[type_id]!='undefined'&&typeof(products[mealTypeSides[type_id]]) != 'undefined'){
                calories = calories + products[mealTypeSides[type_id]].calories/1000;
                    carbs = carbs + products[mealTypeSides[type_id]].carb/1000;
                    protein = protein + products[mealTypeSides[type_id]].protein/1000;
                    fat = fat + products[mealTypeSides[type_id]].fat/1000;
                    name = name + prefix + products[mealTypeSides[type_id]].title;
                    nickname = nickname + prefix + products[mealTypeSides[type_id]].title;
                    prefix = ' & ';
            }
            // Object.keys(thesides).map( (el, idx) => {
            //     if (typeof(products[thesides[el]]) != 'undefined') {
            //         calories = calories + products[thesides[el]].calories/1000;
            //         carbs = carbs + products[thesides[el]].carb/1000;
            //         protein = protein + products[thesides[el]].protein/1000;
            //         fat = fat + products[thesides[el]].fat/1000;
            //         name = name + prefix + products[thesides[el]].title;
            //         nickname = nickname + prefix + products[thesides[el]].title;
            //         prefix = ' & ';
            //     }
            // });
        
        }
        
      
       
        
        if(parent_id==null || parent_id==0){
            /* Add the sides based parent id */
            var sideHtml='';
            if(ParentTypeId[type_id]!=undefined&&mealsSides[ParentTypeId[type_id]]!=undefined){
                sideHtml=Object.keys(mealsSides[ParentTypeId[type_id]]).map((side_id)=>{
                    var dishid=mealsSides[ParentTypeId[type_id]][side_id];
                    if(products[dishid].title!='No side'){
                    // sideHtml+= products[dishid].title+'<br/>';
                        calories = calories + (products[dishid].calories/1000);
                        carbs = carbs + (products[dishid].carb/1000);
                        protein = protein + (products[dishid].protein/1000);
                        fat = fat + (products[dishid].fat/1000);
                    }
                    return (<p className='side-label'>{products[dishid].title}</p>);
                })
            }
            total_calories += calories; 
            total_fat += fat;
            total_protein += protein;
            total_carbs += carbs;
            return (
            
                <div className="mealrow-item">
                    {image} <br/>
                    <span className="mealrow-type">{type}</span>
                    <h4>{name}</h4>
                    {/* <p className="mealrow-realname">{nickname}</p> */}
                    {sideHtml}
                    {label && <p className="bage-label">{label}</p>}
                    {(products[product_id].dishType == 'composite'||products[product_id].parent_id!=0) && <p><Button
                    color='warning'
                    size='small'
                    onClick={() => {
                    editSides(product_id, timestamp,type_id)
                    }}
                    >
                    Change the Side
                    </Button></p>}
                    <b>Total Calories: {calories}</b><br/>
                    <span className="mealrow-cho">
                    Fat: {fat}g, Protein: {protein}g, Carbs: {carbs}g
                    </span><br/>
                    <p className="mealrow-note-p">{note}</p>
                    <div className="commentSide">
                        <input onChange={ (e) => {
                        this.updateNote(e.target.value, product_id,type_id); 
                    }   } /><br/>
                    <a className="btn-note" onClick={ () => this.saveNoteBtn(product_id,type_id) }>Save Note</a>
                    </div>
                </div>
            );
        }else{
            return '';
        }
        //}
        }
        editRenderItem(value, changeBag, sidecal=false){
            const {timestamp, mealplan, products, typeList, ingredientList, altmeals, socket, order_id, userData, all_notes, pause_start, pause_end, dishLabels, editSides, usersides, sidesList,dishGroups} = this.props;
            const date = moment.unix(timestamp);
            const {edit_type, activemeals, activemealplan} = this.state;
            const {allergies, dislikes, dishlikes, dishdislikes, eliminate_groups} = userData;
			const daynum = parseInt(date.format('DD')) - 1; 
            const product_id = parseInt(value);
            if(products[product_id]!=undefined){
					var active = 'mealrow-item';
					if (activemealplan[timestamp][changeBag][edit_type] == product_id) {
						active = 'mealrow-item mealrow-active-meal';
					}

					var allergens = null;
					var dontlike = null;
					var ing = [];
                    var dont = [];
                    var elig = [];
					var dish_dislike_note = false;
					var dish_like_note = false;

					if (dishdislikes) {
						Object.keys(dishdislikes).map( (id, num) => {
							if (dishdislikes.indexOf(product_id) != -1) {
								dish_dislike_note = true;
								this.updateNotselectableProducts(product_id,'dislike');	
							}
						});
					}

					if (dishlikes) {
						Object.keys(dishlikes).map( (id, num) => {
							if (dishlikes.indexOf(product_id) != -1) {
								dish_like_note = true;		
							}
						});
					}

					if (products[product_id].hasAllergens) {
						allergens = 'Has Allergens:'
						products[product_id].allergens.map( (allergen_id, index) => {
							var hasuser = null;
							if (allergies.indexOf(allergen_id) != -1) {
								hasuser = (<b>Customer's allergy</b>);
								this.updateNotselectableProducts(product_id,'allergy');			
							}
							
							ing.push(<li>{ingredientList[allergen_id]} {hasuser}</li>);
						});
					}
					
					products[product_id].ingredients.map( (ingredient_id, index) => {
						if (dislikes.indexOf(ingredient_id) != -1) {
							dontlike = 'Has Customer\'s dislikes:'
							dont.push(<li>{ingredientList[ingredient_id]}</li>);
							this.updateNotselectableProducts(product_id,'dislike');
						}else if(allergies.indexOf(ingredient_id) != -1){
							ing.push(ingredientList[ingredient_id]);
							this.updateNotselectableProducts(product_id,'allergy');
						}
                    });
                    
                    var is_eliminate=false;
                    if(eliminate_groups && eliminate_groups.length>0 && eliminate_groups.includes(products[product_id].group)){
                        is_eliminate = intersect(products[product_id].group,eliminate_groups).length;   
                        // is_eliminate=true;
                        if(dishGroups&&dishGroups[products[product_id].group]!='undefined'){
                            elig.push(<li>{dishGroups[products[product_id].group]}</li>);
                        }
                    }

					const type = typeList[edit_type].title;
					var name = products[product_id].title;
					var nickname = products[product_id].nickname;
					var calories = products[product_id].calories/1000;
					var carbs = products[product_id].carb/1000;
					var protein = products[product_id].protein/1000;
					var fat = products[product_id].fat/1000;

					// if (usersides && typeof(usersides[timestamp]) != 'undefined') {
					// 		// User's optional sides:
					// 	if (typeof(usersides[timestamp][product_id]) != 'undefined') {
					// 		var thesides = usersides[timestamp][product_id];
					// 		var prefix = ' with ';
					// 		Object.keys(thesides).map( (el, idx) => {
					// 			var side_id = thesides[el].side_id;
					// 			if (typeof(products[side_id]) != 'undefined' && thesides[el].type == 'alternative') {
					// 				calories = calories + products[side_id].calories/1000;
					// 				carbs = carbs + products[side_id].carb/1000;
					// 				protein = protein + products[side_id].protein/1000;
					// 				fat = fat + products[side_id].fat/1000;
					// 				name = name + prefix + products[side_id].title;
					// 				nickname = nickname + prefix + products[side_id].title;
					// 				prefix = ' & ';
					// 			}
					// 		});
					// 	}
					// } else {
						var thesides = products[product_id].all_sides;
                        var prefix = ' with ';
                        var mealTypeSides = products[product_id].mealTypeSides;
                        var prefix = ' with ';
                        if(mealTypeSides[edit_type]!='undefined'&&typeof(products[mealTypeSides[edit_type]]) != 'undefined'){
                            calories = calories + products[mealTypeSides[edit_type]].calories/1000;
                                carbs = carbs + products[mealTypeSides[edit_type]].carb/1000;
                                protein = protein + products[mealTypeSides[edit_type]].protein/1000;
                                fat = fat + products[mealTypeSides[edit_type]].fat/1000;
                                name = name + prefix + products[mealTypeSides[edit_type]].title;
                                nickname = nickname + prefix + products[mealTypeSides[edit_type]].title;
                                prefix = ' & ';
                        }
						// Object.keys(thesides).map( (el, idx) => {
						// 	if (typeof(products[thesides[el]]) != 'undefined') {
						// 		calories = calories + products[thesides[el]].calories/1000;
						// 		carbs = carbs + products[thesides[el]].carb/1000;
						// 		protein = protein + products[thesides[el]].protein/1000;
						// 		fat = fat + products[thesides[el]].fat/1000;
						// 		name = name + prefix + products[thesides[el]].title;
						// 		nickname = nickname + prefix + products[thesides[el]].title;
						// 		prefix = ' & ';
						// 	}
						// });
					// }

					var label = false;
					if ( dishLabels && products[product_id] && typeof(dishLabels[products[product_id].label_id]) != 'undefined') {
						label = dishLabels[products[product_id].label_id];
					}
					var image = (<img className="mealrow-img" src={NoImg}></img>);
						if (products[product_id].image != null) {
							image = (<img className="mealrow-img" src={products[product_id].image}></img>);
						} 
                        var totalcal=sidecal?(sidecal['scalories']/1000)+calories:calories;
						return (
							<div className={active} onClick={ () => this.changeActive(edit_type, product_id, changeBag) }>
							{image} 
							{/* <span className="mealrow-type">{type}</span><br/> */}
							{nickname && <h4>{nickname}<br/></h4>}
							<p className="mealrow-realname">{name}</p>
							{label && <p className="bage-label">{label}</p>}
                            {sidecal&&<p><b className="sbold">Main Dish</b></p>}
							<b>Total Calories: {calories}</b><br/>
							<span className="mealrow-cho">
								Fat: {fat}g, Protein: {protein}g, Carbs: {carbs}g
							</span>
                            {sidecal&&<div><p><b className="sbold">Including Sides:</b></p><b>Kcal:{totalcal}</b><br/><span className="mealrow-cho">
								Fat: {(sidecal['sfat']/1000)+fat}g, Protein: {(sidecal['sprotein']/1000)+protein}g, Carbs: {(sidecal['scarb']/1000)+carbs}g
							</span></div>}
							<div className="mealrow-allergy">
								{allergens}
								<ul>{ing}</ul>
							</div>
							<div className="mealrow-dislikes">
								{dontlike}
								<ul>{dont}</ul>
                                {is_eliminate&&'Customer Eliminate Groups:'}
                                {is_eliminate&&<ul>{elig}</ul>}
							</div>
								{dish_dislike_note && <div className="mealrow-dislikes-dish">Not to my likes</div>}
								{dish_like_note && <div className="mealrow-likes-dish">Loves this dish</div>}
							</div>
						);
        }else{
            return '';
        }
     }
        isEmptyObject(obj){
            if(obj != 'undefined'){
            for(var key in obj){
                if(obj.hasOwnProperty(key)){
                    return false;
                }
            }
            return true;
        }
        }
	render() {
		const {timestamp, mealplan, products, typeList, ingredientList, altmeals, altmealSides, socket, order_id, userData, all_notes, pause_start, pause_end, dishLabels, editSides, usersides, sidesList,ParentTypeId,showSideModal,diet_id} = this.props;
		const {edit, activemeals, notes, mealsGroup,editBag, mealsSides} = this.state;
		const date = moment.unix(timestamp);
		var total_calories = 0;  
		var total_fat = 0;
		var total_protein = 0;
		var total_carbs = 0;
		if (pause_start && timestamp >= pause_start && timestamp <= pause_end) {
			return null;
		}
		if (typeof(userData) != 'undefined') {
			const {allergies, dislikes, dishlikes, dishdislikes, eliminate_groups} = userData;
            var items = [];
            var itemsNew = {};
            itemsNew[timestamp] = {};
            var menu = '';
            // RENDER INITIAL DATA
            if (!edit) {
            Object.keys(mealsGroup[timestamp]).forEach((keys,indx)=>{
                items.push ({
                    title: keys,
                    menus: this.renderDefaultMenu(keys,indx),
                    html: mealsGroup[timestamp][keys].map((values) =>{
                        values.mealType_id;
                        values.dish_id;
                        return this.renderItem(values.mealType_id,values.dish_id,keys,values.delivery_name);
                    })
                });
                // if(keys == 'initialBag'){
                //     items['menus'] = <ul className="mealrow-dish-groups">
                //     <li onClick={ () => this.editDay(keys) }>Change items</li>
                //     <li className="can-change">You can change the selection on your own</li>
                //     <li className="can-change mealrow-calories-summary"><b>Calories:</b> {total_calories}, <b>Fat:</b> {total_fat}, <b>Protein:</b> {total_protein}, <b>CHO:</b> {total_carbs} </li>
                //     </ul>;
                // }
                
                    itemsNew[timestamp][keys] = items;
                
            });
        }
            // RENDER DATA FOR EDIT PLAN
            var sidesContent='';
			if (edit) {
                const {edit_type, activemeals, editBag} = this.state;
                const daynum = parseInt(date.format('DD')) - 1; 
                let sidecal={};
                let scalories=0,scarb=0,sprotein=0,sfat=0;
                let childTypeId = ParentTypeId[edit_type];
                sidesContent = mealsSides[childTypeId]!=undefined? Object.keys(mealsSides[childTypeId]).map((sideId, idx) => {
                   const dishId=mealsSides[childTypeId][sideId] || null;
                   const sideDish = altmealSides[diet_id][childTypeId][sideId][daynum] || [];
                   const { title, image, calories, carb, protein ,fat } = products[dishId] || {};
                   const imgUrl = image ? `${image}` : NoImg;
                   if(title!='No side'){
                   scalories+=calories;
                   scarb+=carb;
                   sprotein+=protein;
                   sfat+=fat;
                   }
                  return (
                    <div className='mealrow-sidedish' key={idx}>
                      <p className='mealrow-img'  >
                            <img className="mealrow-img" src={imgUrl}></img>
                      </p>
                      <p className='mealrow-realname sbold'>{title}</p>
                      {title!='No side'&&<p>Kcal:{calories/1000}&nbsp;Pro {protein/1000}&nbsp;Fat {fat/1000}&nbsp;CHO {carb?carb/1000:0}g</p>}
                      <a href="javascript:void(0);" className="link" onClick={() =>showSideModal(sideDish,products, dishId, sideId,+ParentTypeId[edit_type],this.changeMealside)}>Change Side</a>
                    </div>
                  );
               
                }) : [];
                 sidecal=(sidesContent.length>0)?{scalories,scarb,sprotein,sfat}:false;
                 Object.keys(mealsGroup[timestamp]).forEach((keys)=>{
                    if(keys == editBag){
                        items.push ({
                            edititle: keys,
                            editmenus: this.renderEditMenu(keys),
                            edithtml: (
                                altmeals[diet_id][edit_type][daynum].filter(function (dishId) {
                                    const { group,spicy } = products[dishId] || {};
                                    let isEliminate = true;
                                    if(eliminate_groups.length>0){ 
                                    //   isEliminate = !(eliminate_groups.indexOf(group)>-1); 
                                      isEliminate = !(intersect(group,eliminate_groups).length);   
                                      if(isEliminate){
                                        isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
                                      }
                                    }
                                    if(dishdislikes.length>0 && isEliminate){
                                      isEliminate = !(dishdislikes.indexOf(+dishId)>-1); 
                                    }
                                    return isEliminate;
                                }).map((value, index) => {
                                    return this.editRenderItem(value, keys,sidecal);
                                })
                            )
                        });
                    }else{
                        items.push ({
                            edititle: keys,
                            editmenus: this.renderDefaultMenu(keys),
                            edithtml: mealsGroup[timestamp][keys].map((values) =>{
                                values.mealType_id;
                                values.dish_id;
                                return this.renderItem(values.mealType_id,values.dish_id,keys,values.delivery_name);
                            })
                        });
                    }
                    itemsNew[timestamp][keys] = items;
                });
            
                
            } 
            

			return (
                <div className="mealrow-day">
                    <h3 className="mealrow-date">{date.format('dddd Do MMM, YYYY')}</h3>
                        {!edit && (items.map((values)=>{
                        var titleTxt = values.title;
                        var htmlDom = values.html;
                        var menu = values.menus;
                        return(
                        <div>
                        <div className="mealrow-day-header">
                        {menu}
                        </div>
                        
                        <div className="mealrow-meals">
                        { htmlDom}
                        </div>
                        </div>
                        );
                        })
                        )}
                    
                    {edit && (itemsNew[timestamp][editBag].map((values)=>{
                        var titleTxt = values.edittitle;
                        var htmlDom = values.edithtml;
                        var menu = values.editmenus;
                        return(
                        <div>
                        <div className="mealrow-day-header">
                        {menu}
                        </div>
                        
                        <div className="mealrow-meals">
                        {sidesContent!='' && <div className="mealrow-item side-dish-row">{sidesContent}</div>}
                        { htmlDom}
                        </div>
                        </div>
                        );
                        })
                    )}
                </div>
                );
		} else {
			return null;
		}
	}
}

export default Days;